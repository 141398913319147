<template>
  <div>
    <nav class="bg-light fixed-top nav wid">
      <div class="container">
        <span class="logo">
          <!-- <img src="../assets/logo.png" alt="" /> -->
          四川名优世汇药业有限公司
        </span>
        <div class="btns">
          <ul>
            <router-link to="/index" class="link" active-class="active">
              <li>首页</li></router-link
            ><router-link to="/productcenter" class="link" active-class="active">
              <li>产品中心</li></router-link
            >
            <router-link to="/companyprofile" class="link" active-class="active">
              <li>公司简介</li></router-link
            >
            <router-link to="/newscenter" class="link" active-class="active">
              <li>新闻中心</li></router-link
            >
            <router-link to="/information" class="link" active-class="active">
              <li>招聘信息</li></router-link
            >
            <router-link to="/contact" class="link" active-class="active">
              <li>联系我们</li></router-link
            >
            <!-- <router-link to="/downloadApp" class="link" active-class="active">
              <li>APP下载</li>
            </router-link> -->
          </ul>
        </div>
      </div>
    </nav>
    <div style="height: 71px"></div>
    <router-view></router-view>
    <footer class="footer wid">
      <div class="footer-total">
        <div class="footercotent">
          <p class="contenttitle">四川名优世汇药业有限公司</p>
          <p class="pwidth" style="font-size: 24px">
            公司总部：四川省成都金牛高新技术产业园区信息园东路99号大成仓智汇园A-207号
          </p>
          <!-- <p class="pwidth">仓储基地：四川省成都市温江区金府路中段51号</p> -->
        </div>
        <div class="footercotent">
          <!-- <p class="contenttitle">联系我们</p> -->
          <p class="pwidth">联系电话：028-62606321</p>
          <!-- <p class="pwidth">邮箱：11364309@qq.com</p> -->
          <p class="pwidth">
            《互联网药品信息服务资格证书》编号:（川）-非经营性-2019-0045
          </p>
          <!-- <p class="pwidth">
            ICP备案号：<a href="https://beian.miit.gov.cn" target="_balnk" style="color: #fff;">粤ICP备2023072083号-1</a>
          </p> -->
          <p class="pwidth pRed">
            *本网站未发布麻醉药品、精神药品、医疗用毒性药品、放射性药品、戒毒药品和医疗机构制剂的产品信息。
          </p>
        </div>
      </div>

      <div class="footer-title">
        <span class="title1"
          >Copyright© 2022-2025  ALL Rights Reserved
        </span>
        <!-- <a href="https://beian.miit.gov.cn" target="_balnk" class="title2" style="color: #288ef6">吉ICP备2021008710号-2</a> -->
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "home",
};
</script>
<style lang='scss' scoped>
.footer-total {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: left;

  .footercotent {
    margin-right: 174px;
    width: 100%;
  }

  .contenttitle {
    font-size: 24px;
    width: 100%;
  }
}

.footer-title {
  margin-top: 115px;
  width: 100%;
  font-size: 24px;
}

.pwidth {
  // width: 302px;
  font-size: 24px;
  font-family: DengXian;
  font-weight: bold;
  color: #e6e6e6;
  // margin-top: 30px;
}

.pRed {
  color: red;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.nav {
  padding: 7px 14px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-size: 18px;
  font-weight: bold;
}

.btns {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

ul {
  display: flex;
  list-style: none;
  color: #666;
  font-size: 14px;
}

li {
  padding: 5px 10px;
  cursor: pointer;
}

li:hover {
  color: #212529;
}

.active {
  color: #fe2a1e !important;
  font-weight: bolder;
}

.footer {
  // margin-top: 14px;
  padding-top: 42px;
  background: #353c44;
  height: 390px;
  color: #fff;
}

.row {
  margin-bottom: 42px;
  padding: 0 15px;
  width: 100%;
}

.ul {
  font-size: 16px;
  justify-content: space-around;
}

.content {
  display: flex;
}

.inclues {
  flex-grow: 1;
}

.inclues div {
  margin-bottom: 7px;
  text-align: left;
  font-size: 14px;
}

.inclues .head {
  font-size: 16px;
}

.foot {
  border-top: 1px solid #dee2e6;
  line-height: 3;
  font-size: 14px;
  color: #ff0000;
}

.foot div:last-child {
  color: #6c757d;
}
</style>